<template>
    <div class="Founder">
        <p class="title justify">
            创始人/总经理&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
        <p class="p justify">
 
        </p>
        <p class="p justify">
        
        </p>
        <p class="p justify">

        </p>
        <p class="p justify">
 
        </p>
        <p class="p justify">
 
        </p>
    </div>
</template>

<script>
export default {
  name: 'Founder',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', '复数智科创始人')
        this.$emit('isShowA', false)
      }
}
</script>
<style lang="less" scoped>
.Founder{
  margin-bottom:6.25rem;
    .title{
      font-weight: 600;
      font-size: 1.125rem;
      margin-bottom: 1rem!important;
    }
    p{
      margin-bottom: 0!important;
    }
}
</style>
